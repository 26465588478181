<template>
  <el-main>
    <el-button type="primary" size="small" @click="operateFn(1)">添加机器人</el-button>
    <el-form class="el-form-search" style="margin-top: 10px" label-width="100px">
      <el-form-item label="昵称：">
        <el-input size="small" v-model="dataInfo.nickname" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker size="small" v-model="dataInfo.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="机器人性别：">
        <el-select size="small" v-model="dataInfo.sex" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="男" :value="1"></el-option>
          <el-option label="女" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="getListInfo(1)" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="编号" align="center"></el-table-column>
      <el-table-column label="机器人信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :avatar="row.avatar" :name="row.nickname"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column label="性别" align="center">
        <template v-slot="{ row }">
          {{ Number(row.sex) == 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="operateFn(2, row)">编辑</el-button>
          <el-button type="text" size="small" @click="deleteRobot(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="dataInfo.total" :page="dataInfo.page" :pageNum="dataInfo.rows" @updatePageNum="updateData"></Paging>
    <el-dialog :title="mode == 1 ? '添加机器人' : '编辑机器人'" :visible.sync="showDialog" width="500px">
      <el-form>
        <el-form-item label="机器人昵称" label-width="120">
          <el-input style="width: 200px" placeholder="请输入机器人呢昵称" v-model="updateDataItem.nickname"></el-input>
        </el-form-item>
        <el-form-item label="机器人头像" label-width="120">
          <ReadyUploadSource
            :path="updateDataItem.avatar || ''"
            @getSource="val => (updateDataItem.avatar = val.path)"
            @removeThis="updateDataItem.avatar = ''"
            :showStyle="{
              width: '120px',
              height: '120px',
              borderRidus: '50%',
            }"
          ></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="机器人性别" label-width="120">
          <el-radio-group v-model="updateDataItem.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="sureAddOrEdit">确 定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    UserInfo,
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      getDateformat,
      dataInfo: {
        page: 1,
        rows: 10,
        list: [],
        total: 0,
        nickname: '',
        time: [],
        sex: 0,
      },
      showDialog: false,
      updateDataItem: {},
      //   1-添加 2-编辑
      mode: 1,
    };
  },
  created() {
    this.getListInfo();
  },
  methods: {
    searchClear() {
      this.dataInfo = {
        page: 1,
        rows: 10,
        list: [],
        total: 0,
        nickname: '',
        time: [],
      };
      this.getListInfo();
    },
    deleteRobot(data) {
      this.$confirm('是否删除该机器人', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.serviceProvider.robotDelete, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getListInfo();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    sureAddOrEdit() {
      let obj = {
        nickname: this.updateDataItem.nickname,
        avatar: this.updateDataItem.avatar,
        sex: this.updateDataItem.sex,
      };
      let url = this.$api.serviceProvider.robotAdd;
      if (this.mode === 2) {
        obj.id = this.updateDataItem.id;
        url = this.$api.serviceProvider.robotEdit;
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          if (this.mode == 1) {
            this.getListInfo();
            this.showDialog = false;
            this.$message.success('添加成功');
          }
          if (this.mode == 2) {
            this.getListInfo();
            this.showDialog = false;
            this.$message.success('编辑成功');
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    operateFn(mode, data) {
      this.mode = mode;
      if (mode == 1) {
        this.updateDataItem = {
          avatar: '',
          nickname: '',
          sex: '2',
        };
      }
      if (mode == 2) {
        this.updateDataItem = data;
      }
      this.showDialog = true;
    },
    updateData(val, status) {
      if (status == 0) {
        this.dataInfo.rows = val;
      } else {
        this.dataInfo.page = val;
      }
      this.getListInfo();
    },
    getListInfo(style) {
      if (style) {
        this.dataInfo.page = 1;
      }
      let obj = {
        page: this.dataInfo.page,
        rows: this.dataInfo.rows,
      };
      if (this.dataInfo.nickname) obj.nickname = this.dataInfo.nickname;
      if (this.dataInfo.sex) obj.sex = this.dataInfo.sex;
      if (this.dataInfo.time?.length) {
        obj.end_time = this.dataInfo.time[1] / 1000;
        obj.start_time = this.dataInfo.time[0] / 1000;
        if (obj.end_time === obj.start_time) {
          obj.end_time = obj.start_time + 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.serviceProvider.robotList, obj).then(res => {
        if (res.code == 0) {
          this.dataInfo.list = res.result.list;
          this.dataInfo.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
}
</style>
